.cqui-typeahead {
  &.cqui-typeahead-hint {
    max-height: 338px;
    overflow-y: auto;
  }
}

.typeahead {
  &[template-url="aeTypeaheadHint"] {
    padding: 0;
    background-color: $ship-gray;

    &:before {
      content: "(GL Code) General Business Description";
      background-color: $ship-gray;
      color: $white;
      font-style: italic;
      padding: 5px 20px;
      display: flex;
    }

    &:after {
      content: "";
      top: 0;
      position: absolute;
      height: 26px;
    }

    > li {
      background-color: $white;

      &:last-child {
        padding-bottom: 5px;
      }
    }
  }
}
